const cueSet3 = [
  {
    duration: 0.06147537499999878,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 384000,
    time: 12.295075,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 403200,
    time: 12.909828749999999,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 414720,
    time: 13.278680999999999,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.06147537499999878,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 445440,
    time: 14.262287,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 483840,
    time: 15.4917945,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 506880,
    time: 16.229499,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 533760,
    time: 17.09015425,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 537600,
    time: 17.213105,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 545280,
    time: 17.4590065,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 568320,
    time: 18.196711,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 599040,
    time: 19.180317,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 606720,
    time: 19.4262185,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 629760,
    time: 20.163923,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 648960,
    time: 20.77867675,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 660480,
    time: 21.147529,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 691200,
    time: 22.131135,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 729600,
    time: 23.3606425,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 752640,
    time: 24.098347,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 779520,
    time: 24.95900225,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 783360,
    time: 25.081953,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 791040,
    time: 25.3278545,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 814080,
    time: 26.065559,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 844800,
    time: 27.049165,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 852480,
    time: 27.2950665,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 875520,
    time: 28.032771,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 894720,
    time: 28.64752475,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 906240,
    time: 29.016377,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 936960,
    time: 29.999983,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 975360,
    time: 31.2294905,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 998400,
    time: 31.967195,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1025280,
    time: 32.82785025,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1029120,
    time: 32.950801,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1036800,
    time: 33.1967025,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1059840,
    time: 33.934407,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1090560,
    time: 34.918013,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1098240,
    time: 35.1639145,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1121280,
    time: 35.901619,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1140480,
    time: 36.51637275,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1152000,
    time: 36.885225,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1182720,
    time: 37.868831,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1221120,
    time: 39.0983385,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1244160,
    time: 39.836043,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1271040,
    time: 40.69669825,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1274880,
    time: 40.819649,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1282560,
    time: 41.0655505,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1305600,
    time: 41.803255,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1336320,
    time: 42.786861,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1344000,
    time: 43.0327625,
    velocity: 0.23622047244094488,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1367040,
    time: 43.770467,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1386240,
    time: 44.38522075,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 37,
    name: "C#2",
    ticks: 1397760,
    time: 44.754073,
    velocity: 0.6299212598425197,
  },
];

export default cueSet3;
