const cueSet2 = [
  {
    duration: 7.868848,
    durationTicks: 245760,
    midi: 40,
    name: "E2",
    ticks: 983040,
    time: 31.475392,
    velocity: 0.7874015748031497,
  },
  {
    duration: 5.901635999999996,
    durationTicks: 184320,
    midi: 38,
    name: "D2",
    ticks: 1044480,
    time: 33.442604,
    velocity: 0.7874015748031497,
  },
  {
    duration: 3.934424,
    durationTicks: 122880,
    midi: 31,
    name: "G1",
    ticks: 1105920,
    time: 35.409816,
    velocity: 0.7874015748031497,
  },
  {
    duration: 1.9672120000000035,
    durationTicks: 61440,
    midi: 35,
    name: "B1",
    ticks: 1167360,
    time: 37.377027999999996,
    velocity: 0.7874015748031497,
  },
  {
    duration: 7.868848,
    durationTicks: 245760,
    midi: 40,
    name: "E2",
    ticks: 1228800,
    time: 39.34424,
    velocity: 0.7874015748031497,
  },
  {
    duration: 5.901635999999996,
    durationTicks: 184320,
    midi: 38,
    name: "D2",
    ticks: 1290240,
    time: 41.311452,
    velocity: 0.7874015748031497,
  },
  {
    duration: 3.934424,
    durationTicks: 122880,
    midi: 31,
    name: "G1",
    ticks: 1351680,
    time: 43.278664,
    velocity: 0.7874015748031497,
  },
  {
    duration: 1.9672120000000035,
    durationTicks: 61440,
    midi: 35,
    name: "B1",
    ticks: 1413120,
    time: 45.245875999999996,
    velocity: 0.7874015748031497,
  },
  {
    duration: 7.868848,
    durationTicks: 245760,
    midi: 40,
    name: "E2",
    ticks: 1474560,
    time: 47.213088,
    velocity: 0.7874015748031497,
  },
  {
    duration: 5.901635999999996,
    durationTicks: 184320,
    midi: 38,
    name: "D2",
    ticks: 1536000,
    time: 49.1803,
    velocity: 0.7874015748031497,
  },
  {
    duration: 3.934424,
    durationTicks: 122880,
    midi: 31,
    name: "G1",
    ticks: 1597440,
    time: 51.147512,
    velocity: 0.7874015748031497,
  },
  {
    duration: 1.9672120000000035,
    durationTicks: 61440,
    midi: 35,
    name: "B1",
    ticks: 1658880,
    time: 53.114723999999995,
    velocity: 0.7874015748031497,
  },
];

export default cueSet2;
