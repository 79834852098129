const cueSet1 = [
  {
    name: "E2",
    midi: 40,
    time: 0,
    velocity: 0.7874015748031497,
    duration: 7.868848,
  },
  {
    name: "D2",
    midi: 38,
    time: 1.967212,
    velocity: 0.7874015748031497,
    duration: 5.901636,
  },
  {
    name: "G1",
    midi: 31,
    time: 3.934424,
    velocity: 0.7874015748031497,
    duration: 3.934424,
  },
  {
    name: "B1",
    midi: 35,
    time: 5.901636,
    velocity: 0.7874015748031497,
    duration: 1.967212,
  },
  {
    name: "E2",
    midi: 40,
    time: 7.868848,
    velocity: 0.7874015748031497,
    duration: 7.868848,
  },
  {
    name: "D2",
    midi: 38,
    time: 9.83606,
    velocity: 0.7874015748031497,
    duration: 5.901636,
  },
  {
    name: "G1",
    midi: 31,
    time: 11.803272,
    velocity: 0.7874015748031497,
    duration: 3.934424,
  },
  {
    name: "B1",
    midi: 35,
    time: 13.770484,
    velocity: 0.7874015748031497,
    duration: 1.967212,
  },
  {
    name: "E2",
    midi: 40,
    time: 15.737696,
    velocity: 0.7874015748031497,
    duration: 7.868848,
  },
  {
    name: "D2",
    midi: 38,
    time: 17.704908,
    velocity: 0.7874015748031497,
    duration: 5.901636,
  },
  {
    name: "G1",
    midi: 31,
    time: 19.67212,
    velocity: 0.7874015748031497,
    duration: 3.934424,
  },
  {
    name: "B1",
    midi: 35,
    time: 21.639332,
    velocity: 0.7874015748031497,
    duration: 1.967212,
  },
  {
    name: "E2",
    midi: 40,
    time: 23.606544,
    velocity: 0.7874015748031497,
    duration: 7.868848,
  },
  {
    name: "D2",
    midi: 38,
    time: 25.573756,
    velocity: 0.7874015748031497,
    duration: 5.901636,
  },
  {
    name: "G1",
    midi: 31,
    time: 27.540968,
    velocity: 0.7874015748031497,
    duration: 3.934424,
  },
  {
    name: "B1",
    midi: 35,
    time: 29.50818,
    velocity: 0.7874015748031497,
    duration: 1.967212,
  },
  {
    name: "E2",
    midi: 40,
    time: 31.475392,
    velocity: 0.7874015748031497,
    duration: 7.868848000000014,
  },
  {
    name: "D2",
    midi: 38,
    time: 33.442604,
    velocity: 0.7874015748031497,
    duration: 5.9016360000000105,
  },
  {
    name: "G1",
    midi: 31,
    time: 35.409816000000006,
    velocity: 0.7874015748031497,
    duration: 3.934424000000007,
  },
  {
    name: "B1",
    midi: 35,
    time: 37.37702800000001,
    velocity: 0.7874015748031497,
    duration: 1.9672120000000035,
  },
  {
    name: "E2",
    midi: 40,
    time: 39.34424000000001,
    velocity: 0.7874015748031497,
    duration: 7.868848000000014,
  },
  {
    name: "D2",
    midi: 38,
    time: 41.31145200000002,
    velocity: 0.7874015748031497,
    duration: 5.9016360000000105,
  },
  {
    name: "G1",
    midi: 31,
    time: 43.27866400000002,
    velocity: 0.7874015748031497,
    duration: 3.934424000000007,
  },
  {
    name: "B1",
    midi: 35,
    time: 45.245876000000024,
    velocity: 0.7874015748031497,
    duration: 1.9672120000000035,
  },
  {
    name: "E2",
    midi: 40,
    time: 47.21308800000003,
    velocity: 0.7874015748031497,
    duration: 7.868848000000014,
  },
  {
    name: "D2",
    midi: 38,
    time: 49.18030000000003,
    velocity: 0.7874015748031497,
    duration: 5.9016360000000105,
  },
  {
    name: "G1",
    midi: 31,
    time: 51.147512000000035,
    velocity: 0.7874015748031497,
    duration: 3.934424000000007,
  },
  {
    name: "B1",
    midi: 35,
    time: 53.11472400000004,
    velocity: 0.7874015748031497,
    duration: 1.9672120000000035,
  },
];

export default cueSet1;
