const cueSet4 = [
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 491520,
    time: 15.737696,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.06147537499999878,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 499200,
    time: 15.9835975,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 518400,
    time: 16.59835125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 522240,
    time: 16.721302,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 529920,
    time: 16.9672035,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 533760,
    time: 17.09015425,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 537600,
    time: 17.213105,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 541440,
    time: 17.33605575,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 549120,
    time: 17.58195725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 552960,
    time: 17.704908,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 560640,
    time: 17.9508095,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 579840,
    time: 18.56556325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 583680,
    time: 18.688513999999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 591360,
    time: 18.9344155,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 610560,
    time: 19.54916925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 614400,
    time: 19.67212,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 622080,
    time: 19.9180215,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 641280,
    time: 20.53277525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 645120,
    time: 20.655726,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 652800,
    time: 20.9016275,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 672000,
    time: 21.51638125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 675840,
    time: 21.639332,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 683520,
    time: 21.8852335,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 702720,
    time: 22.49998725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 706560,
    time: 22.622937999999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 714240,
    time: 22.8688395,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 733440,
    time: 23.48359325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 737280,
    time: 23.606544,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 744960,
    time: 23.852445499999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 764160,
    time: 24.46719925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 768000,
    time: 24.59015,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 775680,
    time: 24.8360515,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 779520,
    time: 24.95900225,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 783360,
    time: 25.081953,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 787200,
    time: 25.20490375,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 794880,
    time: 25.45080525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 798720,
    time: 25.573756,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 806400,
    time: 25.819657499999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 825600,
    time: 26.43441125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 829440,
    time: 26.557361999999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 837120,
    time: 26.8032635,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 856320,
    time: 27.41801725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 860160,
    time: 27.540968,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 867840,
    time: 27.786869499999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 887040,
    time: 28.40162325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475374999997,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 890880,
    time: 28.524574,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 898560,
    time: 28.7704755,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 917760,
    time: 29.38522925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 921600,
    time: 29.50818,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 929280,
    time: 29.754081499999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 948480,
    time: 30.36883525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 952320,
    time: 30.491785999999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 960000,
    time: 30.7376875,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 979200,
    time: 31.35244125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 983040,
    time: 31.475392,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 990720,
    time: 31.721293499999998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1009920,
    time: 32.33604725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1013760,
    time: 32.458998,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1021440,
    time: 32.704899499999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1025280,
    time: 32.82785025,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1029120,
    time: 32.950801,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1032960,
    time: 33.07375175,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1040640,
    time: 33.31965325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.06147537499999345,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1044480,
    time: 33.442604,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1052160,
    time: 33.6885055,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1071360,
    time: 34.303259249999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1075200,
    time: 34.42621,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1082880,
    time: 34.6721115,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1102080,
    time: 35.28686525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1105920,
    time: 35.409816,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1113600,
    time: 35.6557175,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1132800,
    time: 36.27047125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1136640,
    time: 36.393422,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1144320,
    time: 36.639323499999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1163520,
    time: 37.25407725,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1167360,
    time: 37.377027999999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1175040,
    time: 37.6229295,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1194240,
    time: 38.237683249999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1198080,
    time: 38.360634,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1205760,
    time: 38.6065355,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1224960,
    time: 39.22128925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1228800,
    time: 39.34424,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1236480,
    time: 39.5901415,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1255680,
    time: 40.20489525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1259520,
    time: 40.327846,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1267200,
    time: 40.573747499999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1271040,
    time: 40.69669825,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1274880,
    time: 40.819649,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1278720,
    time: 40.94259975,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1286400,
    time: 41.18850125,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.06147537499999345,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1290240,
    time: 41.311452,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1297920,
    time: 41.5573535,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1317120,
    time: 42.172107249999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1320960,
    time: 42.295058,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1328640,
    time: 42.5409595,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1347840,
    time: 43.15571325,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1351680,
    time: 43.278664,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1359360,
    time: 43.5245655,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1378560,
    time: 44.13931925,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1382400,
    time: 44.26227,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1390080,
    time: 44.508171499999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1409280,
    time: 45.12292525,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1413120,
    time: 45.245875999999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1420800,
    time: 45.4917775,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1440000,
    time: 46.106531249999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1443840,
    time: 46.229482,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1451520,
    time: 46.4753835,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.061475375000000554,
    durationTicks: 1920,
    midi: 36,
    name: "C2",
    ticks: 1470720,
    time: 47.09013725,
    velocity: 0.6299212598425197,
  },
];

export default cueSet4;
